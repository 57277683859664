<template>
	<el-select :value="value"
				v-loading="loading"
				@change="emit"
				clearable
				@clear="emit(null)"
				filterable
				:disabled="disabled"
				:placeholder="$t('select.selectDrzave')">
		<el-option v-for="val in drzave"
					:key="val.id"
					:value="val.id"
					:label="val.naziv">
			<span style="float: left">{{ val.naziv }}</span>
			<span style="float: right; color: #8492a6; font-size: 13px">{{ val.skraceno }}</span>
		</el-option>
	</el-select>
</template>

<script>
export default {
	name: 'select-drzave',
	props: ['value', 'disabled'],
	data() {
		return {
			drzave: [],
			loading: true
		};
	},
	mounted() {
		this.$get('drzave', { limit: 2000 }).then(data => {
			this.drzave = data.content;
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
		},
		get(id) {
			return this.drzave.find(drzave => drzave.id === id);
		}
	}
};
</script>

