<template>
	<el-form class="page" :model="busStatus" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goTo', '/bus-status')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('busStatus.linija')" prop="id_linije">
					<select-linije v-model="busStatus.id_linije" :disabled="id > 0" v-loading="linijeLoading" @change="linijaPromena" class="width-full" ref="linije"></select-linije>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('busStatus.drzava')" prop="id_drzava_polaska">
					<select-drzave v-if="id > 0" v-model="busStatus.id_drzava_polaska" :disabled="true" class="width-full"></select-drzave>
					<el-select v-else v-model="busStatus.id_drzava_polaska"
								v-loading="linijeLoading"
								@change="okreni()"
								class="width-full"
								:placeholder="$t('select.selectDrzave')">
						<el-option v-for="val in drzaveLista"
									:key="val.id"
									:value="val.id"
									:label="val.naziv">
							<span style="float: left">{{ val.naziv }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">{{ val.skraceno }}</span>
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="6" :md="3" :lg="2" :xl="2" v-if="drzaveLista.length === 1">
				<el-form-item :label="$t('busStatus.pravac')">
					<el-button @click="okrneiSve()" :disabled="id > 0" icon="fas fa-sync-alt"> {{$t('busStatus.okreni')}}</el-button>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('busStatus.mobilniVozaca')">
					<el-input type="text" v-model="busStatus.mobilni_vozaca" :maxLength="30"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('busStatus.aktivno')">
					<el-switch
							v-model="busStatus.aktivno"
							active-value="DA"
							inactive-value="NE"
							:active-text="$t('global.aktivan')"
							:inactive-text="$t('global.neaktivan')"
							active-color="#13ce66"
							inactive-color="#ff4949"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('busStatus.gotovo')">
					<el-switch
							v-model="busStatus.gotovo"
							:active-value="1"
							:inactive-value="0"
							:active-text="$t('global.da')"
							:inactive-text="$t('global.ne')"
							active-color="#13ce66"
							inactive-color="#ff4949"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item :label="$t('busStatus.opis')">
					<el-input type="textarea" v-model="busStatus.opis" :maxLength="250"/>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item :label="$t('busStatus.info')">
					<el-input type="textarea" v-model="busStatus.info"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item>
					<el-table v-if="busStatus.detalji.length && busStatus.id_linije"
								:data="busStatus.detalji"
								v-loading="linijeLoading"
								:row-class-name="tableRowClass"
								border
								style="width: 100%">
							<el-table-column
									prop="mesto"
									:label="$t('busStatus.mesto')"
									width="300">
								<template slot-scope="scope">
									<span v-if="scope.row.id_mesta !== -1">{{scope.row.mesto}}</span>
									<el-input v-else v-model="scope.row.mesto"></el-input>
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									prop="vreme"
									width="120"
									:label="$t('busStatus.vreme')">
								<template slot-scope="scope">
									<span v-if="id > 0"><b>{{scope.row.vreme}}</b></span>
									<el-time-select v-else
										class="width-full-important"
										:clearable="false"
										:picker-options="{ start: '00:00', step: '00:01', end: '24:00' }"
										:format="'HH:mm'"
										v-model="scope.row.vreme">
									</el-time-select>
								</template>
							</el-table-column>
							<el-table-column
									v-if="id > 0"
									align="center"
									:label="$t('busStatus.vreme')">
								<template slot-scope="scope">
									<el-row justify="space-between">
										<el-col style="width: auto; box-sizing: border-box;">
											<el-checkbox v-model="scope.row.umanjiZa" :label="$t('busStatus.umanjiZa')" class="umanji" border></el-checkbox>
										</el-col>
										<el-col style="width: auto; box-sizing: border-box;">
											<el-time-select class="width-full-important vreme"
												:clearable="false"
												:picker-options="{ start: '00:00', step: '00:01', end: '24:00' }"
												:format="'HH:mm'"
												v-model="scope.row.vremeNovo"/>
										</el-col>
										<el-col style="width: auto; box-sizing: border-box;">
											<el-checkbox v-model="scope.row.samoZa" :label="$t('busStatus.samoZa')" class="samo-za" border/>
										</el-col>
										<el-col style="width: auto; box-sizing: border-box;">
											<el-button v-if="scope.row.mestoPosleGranice === 2" @click="promeni2(scope)" class="promeni">{{$t('global.izmena')}}</el-button>
											<el-button v-else @click="promeni(scope)" class="promeni">{{$t('global.izmena')}}</el-button>
										</el-col>
									</el-row>
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									prop="stigao"
									width="120"
									:label="$t('busStatus.stigao')">
								<template slot-scope="scope">
									<el-checkbox v-model="scope.row.stigao" class="checkbox-in-grid" border></el-checkbox>
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									prop="sakriveno"
									width="120"
									:label="$t('busStatus.sakriveno')">
								<template slot-scope="scope">
									<el-checkbox v-model="scope.row.sakriveno" class="checkbox-in-grid" border></el-checkbox>
								</template>
							</el-table-column>
						</el-table>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit(true)">{{ $t('busStatus.osvezi') }}</el-button>
					<el-button type="info" @click="$utils('goTo', '/bus-status' )">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'bus-status-edit',
	data() {
		return {
			linijeLoading: false,
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			busStatus: {
				id_linije: null,
				id_drzava_polaska: null,
				opis: '',
				mobilni_vozaca: '',
				info: '',
				aktivno: 'DA',
				polazak_dolazak: 0,
				gotovo: 0,
				detalji: [
					// {
					// id_mesta: 1,
					// mesto: null,
					// vreme: '00:18:00',
					// stigao: 0,
					// sakriveno: 0
					// }
				]
			},
			vremeRazlika: [],
			drzaveLista: [],
			rules: {
				id_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_drzava_polaska: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('busStatus.busStatus')
		});

		this.$utils('stopLoadingAfter', [this.getBusStatus()]);
	},
	methods: {
		getBusStatus() {
			this.linijeLoading = false;
			this.fullscreenLoading = true;
			this.onSubmitLoading = false;
			if (!this.formCreate) {
				return this.$get('busStatus', { id: this.id }).then(data => {
					let indexMestaPreGranice = null;
					let skloniRow = false;
					let mestoPosleGranice = 0;
					data.detalji.forEach((val, index) => {
						if (mestoPosleGranice === 1)
							mestoPosleGranice++;

						if (val.id_mesta === -1) {
							mestoPosleGranice++;
							indexMestaPreGranice = index - 1;
							skloniRow = true;
						}

						val.stigao = val.stigao === 1;
						val.sakriveno = val.sakriveno === 1;
						val.skloniRow = skloniRow;
						val.vremeNovo = '00:00';
						val.umanjiZa = false;
						val.samoZa = false;
						val.mestoPosleGranice = mestoPosleGranice;

						if (mestoPosleGranice === 1)
							mestoPosleGranice++;
						else
							mestoPosleGranice = 0;
					});

					if (indexMestaPreGranice !== null) {
						data.detalji.forEach((val, index) => {
							if (data.detalji[indexMestaPreGranice].stigao === true)
								val.skloniRow = indexMestaPreGranice >= index;
						});
					}

					this.busStatus = data;
				});
			}
		},
		onSubmit(osvezi = false) {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = _.cloneDeep(this.busStatus);

				data.detalji.forEach(val => {
					val.stigao = val.stigao === true ? 1 : 0;
					val.sakriveno = val.sakriveno === true ? 1 : 0;
				});

				data = JSON.stringify(data);

				if (osvezi === true) {
					if (this.formCreate) {
						this.$api.busStatus.save(data).then((response) => {
							if (response.body.id) {
								this.$router.push({ params: { id: response.body.id } });
								this.id = this.$route.params.id;
								this.formCreate = this.id === 'create';
								this.$utils('stopLoadingAfter', [this.getBusStatus()]);
							}
						});
					}
					else {
						this.$api.busStatus.update({ id: this.id }, data).then(() => {
							this.$utils('stopLoadingAfter', [this.getBusStatus()]);
						});
					}
				}
				else {
					if (this.formCreate)
						this.$save('busStatus', data);
					else
						this.$update('busStatus', { id: this.id }, data);
				}
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		},
		linijaPromena() {
			this.linijeLoading = true;
			this.busStatus.id_drzava_polaska = null;
			return this.$get('linije', { id: this.busStatus.id_linije }).then(data => {
				this.busStatus.detalji = [];
				this.drzaveLista = [];

				let drzava = null;
				let skloniRow = false;
				data.mesta.forEach(val => {
					this.pushDrzava(val);
					drzava = !drzava ? val.id_drzave : drzava;
					if (val.id_drzave !== drzava) {
						drzava = val.id_drzave;
						skloniRow = true;
						this.busStatus.detalji.push({
							id_mesta: -1,
							mesto: 'Granica',
							vreme: '00:00',
							stigao: false,
							sakriveno: false,
							skloniRow: skloniRow
						});
					}

					this.busStatus.detalji.push({
						id_mesta: val.id_mesta,
						mesto: val.naziv_izvorno,
						vreme: skloniRow ? '00:00:00' : val.vreme_polaska,
						vreme_polaska: val.vreme_polaska,
						vreme_dolaska: val.vreme_dolaska,
						okreni: 0,
						stigao: false,
						sakriveno: false,
						skloniRow: skloniRow
					});
				});
				this.linijeLoading = false;
			}).catch((err) => {
				this.linijeLoading = false;
				return err;
			});
		},
		pushDrzava(val) {
			if (!val.id_drzave > 0)
				return;

			if (!this.busStatus.id_drzava_polaska)
				this.busStatus.id_drzava_polaska = val.id_drzave;

			this.drzaveLista.push({
				id: val.id_drzave,
				naziv: val.drzava,
				skraceno: val.oznaka
			});
			this.drzaveLista = _.uniqBy(this.drzaveLista, 'id');
		},
		okreni() {
			let detalji = this.busStatus.detalji;
			this.busStatus.detalji = [];
			this.$nextTick(() => {
				this.busStatus.detalji = _.reverse(detalji);
				this.busStatus.detalji.map(mesta => {
					if (mesta.id_mesta > 0) {
						if (mesta.okreni === 0) {
							mesta.vreme = mesta.vreme_dolaska;
							mesta.okreni = 1;
						}
						else if (mesta.okreni === 1) {
							mesta.vreme = mesta.vreme_polaska;
							mesta.okreni = 0;
						}
					}
				});
				this.busStatus.detalji.forEach(val => {
					val.skloniRow = !val.skloniRow;
					if (val.skloniRow)
						val.vreme = '00:00:00';
				});
			});
		},
		okrneiSve() {
			this.busStatus.detalji.map(mesta => {
				if (mesta.id_mesta > 0) {
					if (mesta.okreni === 0) {
						mesta.vreme = mesta.vreme_dolaska;
						mesta.okreni = 1;
					}
					else if (mesta.okreni === 1) {
						mesta.vreme = mesta.vreme_polaska;
						mesta.okreni = 0;
					}
				}
			});
			this.busStatus.detalji.reverse();
		},
		tableRowClass({ row }) {
			let classAdd = '';
			if (row.skloniRow === true)
				classAdd += ' hidde';

			if (row.id_mesta === -1)
				classAdd += ' granica';

			if (row.stigao === true && row.id_mesta !== -1)
				classAdd += ' stigao';

			return classAdd;
		},
		promeni(scope) {
			let row = scope.row;
			let vremeNovo = this.moment(new Date('2000-01-01 ' + row.vremeNovo + '')); // Vreme promene
			let umanjiZa = false;
			if (row.samoZa === true) {
				let vreme = this.moment(new Date('2000-01-01 ' + row.vreme + '')); // Vreme start

				if (row.umanjiZa === true || umanjiZa === true) {
					umanjiZa = true;
					vreme = this.moment(vreme).subtract({
						hours: this.moment(vremeNovo).format('HH'),
						minutes: this.moment(vremeNovo).format('mm')
					});
				}
				else {
					vreme = this.moment(vreme).add({
						hours: this.moment(vremeNovo).format('HH'),
						minutes: this.moment(vremeNovo).format('mm')
					});
				}
				row.vreme = this.moment(new Date(vreme)).format('HH:mm');
			}
			else {
				this.busStatus.detalji.forEach((val, index) => {
					if (!val.skloniRow && index >= scope.$index) {
						let vreme = this.moment(new Date('2000-01-01 ' + val.vreme + '')); // Vreme start

						if (val.umanjiZa === true || umanjiZa === true) {
							umanjiZa = true;
							vreme = this.moment(vreme).subtract({
								hours: this.moment(vremeNovo).format('HH'),
								minutes: this.moment(vremeNovo).format('mm')
							});
						}
						else {
							vreme = this.moment(vreme).add({
								hours: this.moment(vremeNovo).format('HH'),
								minutes: this.moment(vremeNovo).format('mm')
							});
						}
						val.vreme = this.moment(new Date(vreme)).format('HH:mm');
					}
				});
			}
		},
		async promeni2(scope) {
			let vremeRazlika = await this.busStatusVremeRazlika();

			let row = scope.row;
			let vremeNovo = this.moment(new Date('2000-01-01 ' + row.vremeNovo + '')); // Vreme promene
			let umanjiZa = false;
			let mestoOd = 0;
			let mestoDo = 0;
			if (row.samoZa === true) {
				let vreme = this.moment(new Date('2000-01-01 ' + row.vreme + '')); // Vreme start
				if (row.umanjiZa === true || umanjiZa === true) {
					umanjiZa = true;
					vreme = this.moment(vreme).subtract({
						hours: this.moment(vremeNovo).format('HH'),
						minutes: this.moment(vremeNovo).format('mm')
					});
				}
				else {
					vreme = this.moment(vreme).add({
						hours: this.moment(vremeNovo).format('HH'),
						minutes: this.moment(vremeNovo).format('mm')
					});
				}
				row.vreme = this.moment(new Date(vreme)).format('HH:mm');
			}
			else {
				this.busStatus.detalji.forEach((val, index) => {
					if (index >= scope.$index) {
						let medjuVreme = 0;
						if (mestoOd === 0)
							mestoOd = val.id_mesta;
						if (mestoDo === 0)
							mestoDo = val.id_mesta;
						if (mestoOd && mestoDo) {
							medjuVreme = _.find(vremeRazlika, function(o) {
								if (o.do_id === mestoDo && o.od_id === mestoOd)
									return o;
								if (o.do_id === mestoOd && o.od_id === mestoDo)
									return o;
							});
						}

						mestoOd = mestoDo;
						mestoDo = 0;

						let vreme = this.moment(new Date('2000-01-01 ' + row.vreme + '')); // Vreme start
						if (medjuVreme) {
							let v = vremeNovo.format('HH:mm').toString();
							vreme = this.moment(new Date('2000-01-01 ' + v + ''));
						}

						if (val.umanjiZa === true || umanjiZa === true) {
							umanjiZa = true;
							vreme = this.moment(vreme).subtract({
								hours: this.moment(vremeNovo).format('HH'),
								minutes: this.moment(vremeNovo).format('mm')
							});
							// if (medjuVreme) vreme = this.moment(vreme).add(medjuVreme.medjuvreme, 'minutes');
						}
						else {
							if (medjuVreme)
								vreme = this.moment(vreme).add(medjuVreme.medjuvreme, 'minutes');
							else {
								vreme = this.moment(vreme).add({
									hours: this.moment(vremeNovo).format('HH'),
									minutes: this.moment(vremeNovo).format('mm')
								});
							}
						}
						val.vreme = vreme.format('HH:mm');
						vremeNovo = this.moment(new Date('2000-01-01 ' + vreme.format('HH:mm') + '')); // Vreme promene
					}
				});
			}
		},
		busStatusVremeRazlika() {
			return this.$get('busStatusVremeRazlika', { id: this.busStatus.id_linije }).then(data => {
				this.vremeRazlika = JSON.stringify(data);
				return data;
			});
		}
	},
	components: {
		selectLinije: require('../../components/select/linije').default,
		selectDrzave: require('../../components/select/drzave').default
	}
};
</script>

<style>
	.el-table__row.hidde {
		display: none;
	}
	.el-table__row.granica {
		background-color: rgb(255, 202, 197);
	}
	.el-table__row.stigao {
		background-color: #bcbcf2;
	}
	.umanji {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		background-color: white !important;
	}
	.vreme > input {
		border-radius: 0px !important;
	}
	.samo-za {
		border-radius: 0px !important;
		background-color: white !important;
	}
	.promeni {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}
</style>
